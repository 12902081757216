import Tags from '../tags';
import IClient, {
  ResourceStatus,
  IClientGroupUsersReports,
  IClientResources,
  IUpdateClientAdminsDto,
  IUpdateClientAutoSyncTime,
  IUpdateClientDto,
  ResourceTypes,
  ICreateClientDto,
} from '../../types/client.d';
import IUser from '../../types/user.d';
import apiSlice from '../api/api.slice';

export enum ActionateResourceAction {
  START = 'start',
  STOP = 'stop',
  STATUS = 'status',
}

interface IGetClientUsers {
  clientId: string;
  role?: string;
}

interface IActionateResource {
  resource: ResourceTypes;
  code: string;
  action: ActionateResourceAction;
}

export interface IClientConfiguration {
  appId: string | null;
  tenantId: string | null;
  hasPips: boolean;
  pipsId: string | null;
  pipsAuthority: string | null;
}

const clientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientConfiguration: builder.query<IClientConfiguration, void>({
      query: () => ({
        url: '/clients/configuration',
        method: 'GET',
      }),
    }),
    getClient: builder.query<IClient, string>({
      query: (clientId: string) => ({
        url: `/clients/${clientId}`,
        method: 'GET',
      }),
      providesTags: [Tags.CLIENT],
    }),
    getClientGroups: builder.query<IClientGroupUsersReports, string>({
      query: (clientId: string) => ({
        url: `/clients/${clientId}/groups`,
        method: 'GET',
      }),
      providesTags: [Tags.GROUPS],
    }),
    getClientUsers: builder.query<IUser[], IGetClientUsers>({
      query: (args: IGetClientUsers) => ({
        url: `/clients/${args.clientId}/users${args.role ? `?role=${args.role}` : ''}`,
        method: 'GET',
      }),
      providesTags: [Tags.CLIENT_USERS],
    }),
    addClient: builder.mutation<IClient, ICreateClientDto>({
      query: (args: ICreateClientDto) => ({
        url: `/clients`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [Tags.CLIENT, Tags.WORKSPACE_REPORTS],
    }),
    getClients: builder.query<IClient[], void>({
      query: () => ({
        url: `/clients`,
        method: 'GET',
      }),
      providesTags: [Tags.CLIENT],
    }),
    updateClient: builder.mutation<void, IUpdateClientDto>({
      query: ({ clientId, ...args }: IUpdateClientDto) => ({
        url: `/clients/${clientId}`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: [Tags.CLIENT, Tags.WORKSPACE_REPORTS],
    }),
    deleteClient: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/clients/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.CLIENT, Tags.WORKSPACE_REPORTS],
    }),
    updateClientAdmins: builder.mutation<void, IUpdateClientAdminsDto>({
      query: (args: IUpdateClientAdminsDto) => ({
        url: `/clients/${args.clientId}/admins`,
        method: 'PUT',
        body: {
          emailList: args.emailList,
        },
      }),
      invalidatesTags: [Tags.CLIENT_USERS],
    }),
    syncClient: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/clients/${id}/sync`,
        method: 'GET',
      }),
      invalidatesTags: [Tags.CLIENT, Tags.USER, Tags.GROUPS],
    }),
    updateAutoSyncTime: builder.mutation<void, IUpdateClientAutoSyncTime>({
      query: ({ id, timeToSync }: IUpdateClientAutoSyncTime) => ({
        url: `/clients/${id}/auto-sync`,
        method: 'PATCH',
        body: {
          timeToSync,
        },
      }),
      invalidatesTags: [Tags.GROUPS],
    }),
    getAllClientsResources: builder.query<IClientResources[], void>({
      query: () => ({
        url: '/clients/resources',
        method: 'GET',
      }),
    }),
    actionateResource: builder.query<{ status: ResourceStatus }, IActionateResource>({
      query: ({ resource, code, action }) => ({
        url: '/clients/resources',
        method: 'POST',
        body: {
          code,
          action,
          resource,
        },
      }),
    }),
  }),
});

export const {
  useGetClientQuery,
  useGetClientsQuery,
  useAddClientMutation,
  useLazyGetClientQuery,
  useSyncClientMutation,
  useGetClientUsersQuery,
  useGetClientGroupsQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useLazyGetClientUsersQuery,
  useLazyGetClientGroupsQuery,
  useUpdateClientAdminsMutation,
  useUpdateAutoSyncTimeMutation,
  useLazyActionateResourceQuery,
  useGetAllClientsResourcesQuery,
  useGetClientConfigurationQuery,
  useLazyGetClientConfigurationQuery,
  useLazyGetAllClientsResourcesQuery,
} = clientApiSlice;

export default clientApiSlice;
