import Tags from '../tags';
import apiSlice from '../api/api.slice';
import { SessionType } from '../../types/user.d';
import IReport, { ReportIcons, SchoolYearSections } from '../../types/report.d';

interface IUpdateReportDto {
  id: string;
  clientId?: string | null;
  authorizedFor?: SessionType[];
  schoolYearSections?: SchoolYearSections[];
}

export interface IUpdateReportSettingsDto {
  reportId: string;
  displayName: string;
  icon: ReportIcons;
  sectionId?: string | null;
}

export interface IUpdateReportOrderDto {
  id: string;
  displayOrder: number;
}

export interface ICreateRLSRoleDto {
  id: string;
  name: string;
  forGuardians: boolean;
}

interface IUpdateRoles {
  name: string;
  id: string;
  priorityOrder: number;
  forGuardians: boolean;
  groupId: string | null;
}

export interface IUpdateRLSRolesDto {
  id: string;
  roles: IUpdateRoles[];
}

const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientReports: builder.query<IReport[], string>({
      query: (clientId: string) => ({
        url: `/reports?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: [Tags.REPORTS],
    }),
    updateReport: builder.mutation<IReport, IUpdateReportDto>({
      query: ({ id, ...args }: IUpdateReportDto) => ({
        url: `/reports/${id}`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: [Tags.WORKSPACE_REPORTS],
    }),
    updateReportSettings: builder.mutation<void, IUpdateReportSettingsDto>({
      query: (args: IUpdateReportSettingsDto) => ({
        url: `/reports/${args.reportId}/display-settings`,
        method: 'PATCH',
        body: { displayName: args.displayName, icon: args.icon, sectionId: args.sectionId },
      }),
      invalidatesTags: [Tags.REPORTS, Tags.SECTIONS],
    }),
    updateReportOrder: builder.mutation<void, IUpdateReportOrderDto[]>({
      query: (order: IUpdateReportOrderDto[]) => ({
        url: '/reports/display-order',
        method: 'PATCH',
        body: { order },
      }),
      invalidatesTags: [Tags.SECTIONS],
    }),
    createReportRLSRole: builder.mutation<void, ICreateRLSRoleDto>({
      query: ({ id, ...args }) => ({
        url: `/reports/${id}/rls-roles`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [Tags.WORKSPACE_REPORTS],
    }),
    updateReportRLSRoles: builder.mutation<void, IUpdateRLSRolesDto>({
      query: ({ id, ...args }) => ({
        url: `/reports/${id}/rls-roles`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: [Tags.WORKSPACE_REPORTS],
    }),
  }),
});

export const {
  useUpdateReportMutation,
  useGetClientReportsQuery,
  useUpdateReportSettingsMutation,
  useUpdateReportOrderMutation,
  useCreateReportRLSRoleMutation,
  useUpdateReportRLSRolesMutation,
} = reportApiSlice;

export default reportApiSlice;
