import { JSX } from 'react';
import { useDispatch } from 'react-redux';
import { List } from 'react-bootstrap-icons';
import SignOutButton from '../Buttons/SignOutButton';
import { getClientLogo } from '../../types/client.d';
import { toggleMobileSidebar } from '../../features/layout/layout.slice';

const clientCode: string = window.location.hostname.split('.')[0];

function Topbar(): JSX.Element {
  const dispatch = useDispatch();

  const onToggleMobileSidebar = () => dispatch(toggleMobileSidebar());

  return (
    <div id="topbar">
      <img src={getClientLogo(clientCode)} alt={`${clientCode} logo`} height={56} />
      <SignOutButton className="topbar-signout" variant="secondary">
        LOG OUT
      </SignOutButton>
      <button type="button" className="topbar-mobile-hamburger" onClick={onToggleMobileSidebar}>
        <List size={40} />
      </button>
    </div>
  );
}

export default Topbar;
