import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import { ChevronRight, ExclamationTriangleFill } from 'react-bootstrap-icons';
import {
  ICreateRLSRoleDto,
  useCreateReportRLSRoleMutation,
  useUpdateReportMutation,
  useUpdateReportRLSRolesMutation,
} from '../../../features/report/reportApi.slice';
import { SessionType } from '../../../types/user.d';
import { IPowerbiReports } from '../../../types/powerbi.d';
import IReport, { IRLSRole, SchoolYearSections } from '../../../types/report.d';
import { useGetAllPowerbiQuery } from '../../../features/powerbi/powerbiApi.slice';
import { setErrorMessage, setSuccessMessage } from '../../../features/layout/layout.slice';
import ReportRLSRolesSettings from '../../../components/ReportRLSRolesSettings/ReportRLSRolesSettings';

interface IClientReports {
  [key: string]: IReport[];
}

interface IPowerbiClientReports extends IPowerbiReports {
  clientReports: IClientReports;
}

function ReportSettings() {
  const dispatch = useDispatch();
  const [updateReport] = useUpdateReportMutation();
  const [createRLSRole] = useCreateReportRLSRoleMutation();
  const [updateRLSRoles] = useUpdateReportRLSRolesMutation();
  const { data: powerbis } = useGetAllPowerbiQuery(true);
  const [deletingRoles, setDeletingRoles] = useState<string[]>([]);
  const [editingRoles, setEditingRoles] = useState<IRLSRole[]>([]);
  const [newRole, setNewRole] = useState<ICreateRLSRoleDto | null>(null);
  const [editingReport, setEditingReport] = useState<IReport | null>(null);
  const [accordionActiveKey, setAccordionActiveKey] = useState<string | null>(null);
  const [workspaceReports, setWorkspaceReports] = useState<IPowerbiClientReports[] | null>(null);

  const isReportUnconfigured = (report: IReport): boolean => {
    return (
      !report.clientId || (report.hasRLS && !report.rlsRoles.length) || !report.authorizedFor.length
    );
  };

  const handleSchoolYearSectionChange = (section: SchoolYearSections, add: boolean) => {
    if (!editingReport) return;
    setEditingReport({
      ...editingReport,
      schoolYearSections: add
        ? [...editingReport.schoolYearSections, section]
        : editingReport.schoolYearSections.filter((s) => s !== section),
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editingReport) return;
    if (newRole) {
      createRLSRole(newRole)
        .then(() => {
          setNewRole(null);
          dispatch(setSuccessMessage('Role created'));
        })
        .catch(() => dispatch(setErrorMessage('Role creation failed')));
    } else {
      const { id, clientId, authorizedFor, schoolYearSections } = editingReport;
      updateReport({ id, clientId, authorizedFor, schoolYearSections })
        .unwrap()
        .then(() => {
          if (editingReport.hasRLS)
            updateRLSRoles({
              id: editingReport.id,
              roles: editingRoles.filter((r) => !deletingRoles.includes(r.id)),
            });
          dispatch(setSuccessMessage(`Report ${editingReport.name} successfully updated`));
        })
        .catch(() => dispatch(setErrorMessage(`Report ${editingReport.name} update failed`)));
    }
  };

  const accordionToggle = (activeKey: string | null) => {
    if (activeKey === accordionActiveKey) {
      setAccordionActiveKey(null);
    } else {
      setAccordionActiveKey(activeKey);
    }
  };

  useEffect(() => {
    if (!accordionActiveKey) {
      setEditingReport(null);
    }
  }, [accordionActiveKey]);

  useEffect(() => {
    if (powerbis)
      setWorkspaceReports(
        powerbis.map((pbi) => {
          const clientReports: IClientReports = { 'No Client': [] };
          pbi.clients.forEach((c) => {
            clientReports[c.name] = [];
          });
          pbi.reports.forEach((r) => {
            const client = pbi.clients.find((c) => c.id === r.clientId);
            clientReports[client?.name ?? 'No Client'].push(r);
          });
          return {
            ...pbi,
            clientReports,
          };
        })
      );
    if (powerbis?.length && editingReport) {
      setEditingReport(
        powerbis
          .find((powerbi) => powerbi.id === accordionActiveKey)
          ?.reports.find((report) => report.id === editingReport.id) ?? null
      );
    }
  }, [powerbis]);

  useEffect(() => {
    setNewRole(null);
    if (editingReport?.hasRLS) setEditingRoles(editingReport.rlsRoles);
    else setEditingRoles([]);
  }, [editingReport]);

  return (
    <div id="reportSettings">
      <span className="report-title"> Reports </span>
      <div className="report-content">
        <div className="left-container">
          <Accordion>
            {workspaceReports?.map((powerbi) => (
              <Accordion.Item key={powerbi.id} eventKey={powerbi.id}>
                <Accordion.Header
                  onClick={() => accordionToggle(powerbi.id)}
                  className="powerbi-name"
                >
                  {powerbi.workspaceName?.toUpperCase()}
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion>
                    {Object.entries(powerbi.clientReports).map(([client, reports]) => (
                      <Accordion.Item key={client} eventKey={client}>
                        <Accordion.Header>{client}</Accordion.Header>
                        <Accordion.Body>
                          {reports.map((report) => (
                            <Button
                              className="report-select-btn"
                              key={report.id}
                              onClick={() => setEditingReport(report)}
                              active={editingReport?.id === report.id}
                            >
                              {isReportUnconfigured(report) && (
                                <ExclamationTriangleFill size={16} className="warning" />
                              )}
                              <span className="report-select-btn-name">{report.name}</span>
                              <ChevronRight />
                            </Button>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="right-container">
          {!editingReport && (
            <div className="d-flex justify-content-center align-items-center my-3 fw-bold">
              Select a report to start editing the settings.
            </div>
          )}
          {editingReport && (
            <Form className="report-settings-container" onSubmit={onSubmit}>
              <Form.Group className="report-form-row">
                <Form.Label className="form-label"> Client </Form.Label>
                <Form.Select
                  value={editingReport.clientId ?? ''}
                  onChange={({ target }) =>
                    setEditingReport({ ...editingReport, clientId: target.value || null })
                  }
                >
                  <option value=""> - </option>
                  {powerbis &&
                    powerbis
                      .find((powerbi) => powerbi.id === accordionActiveKey)
                      ?.clients.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.name}
                        </option>
                      ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="report-form-row" id="report-guardian-switch">
                <Form.Label className="form-label"> Authorized For </Form.Label>
                <Form.Switch
                  label="Staff"
                  checked={editingReport.authorizedFor.includes(SessionType.STAFF)}
                  onChange={({ target }) =>
                    setEditingReport({
                      ...editingReport,
                      authorizedFor: target.checked
                        ? editingReport.authorizedFor.concat(SessionType.STAFF)
                        : editingReport.authorizedFor.filter((t) => t !== SessionType.STAFF),
                    })
                  }
                />
                <Form.Switch
                  label="Guardian"
                  checked={editingReport.authorizedFor.includes(SessionType.GUARDIAN)}
                  onChange={({ target }) =>
                    setEditingReport({
                      ...editingReport,
                      authorizedFor: target.checked
                        ? editingReport.authorizedFor.concat(SessionType.GUARDIAN)
                        : editingReport.authorizedFor.filter((t) => t !== SessionType.GUARDIAN),
                    })
                  }
                />
              </Form.Group>
              {editingReport.authorizedFor.includes(SessionType.GUARDIAN) && (
                <Form.Group className="report-form-row">
                  <Form.Label className="form-label"> School Year Sections </Form.Label>
                  <Form.Check className="report-guardian-years">
                    <div className="report-guardian-year">
                      <Form.Check.Label> Primary </Form.Check.Label>
                      <Form.Check.Input
                        onChange={({ target }) =>
                          handleSchoolYearSectionChange(SchoolYearSections.PRIMARY, target.checked)
                        }
                        checked={editingReport.schoolYearSections.includes(
                          SchoolYearSections.PRIMARY
                        )}
                      />
                    </div>
                    <div className="report-guardian-year">
                      <Form.Check.Label> Middle </Form.Check.Label>
                      <Form.Check.Input
                        onChange={({ target }) =>
                          handleSchoolYearSectionChange(SchoolYearSections.MIDDLE, target.checked)
                        }
                        checked={editingReport.schoolYearSections.includes(
                          SchoolYearSections.MIDDLE
                        )}
                      />
                    </div>
                    <div className="report-guardian-year">
                      <Form.Check.Label> Secondary </Form.Check.Label>
                      <Form.Check.Input
                        onChange={({ target }) =>
                          handleSchoolYearSectionChange(
                            SchoolYearSections.SECONDARY,
                            target.checked
                          )
                        }
                        checked={editingReport.schoolYearSections.includes(
                          SchoolYearSections.SECONDARY
                        )}
                      />
                    </div>
                  </Form.Check>
                </Form.Group>
              )}
              {editingReport.hasRLS && (
                <ReportRLSRolesSettings
                  newRole={newRole}
                  setNewRole={setNewRole}
                  reportId={editingReport.id}
                  editingRoles={editingRoles}
                  deletingRoles={deletingRoles}
                  setEditingRoles={setEditingRoles}
                  clientId={editingReport.clientId}
                  setDeletingRoles={setDeletingRoles}
                />
              )}
              <Button className="form-button" type="submit">
                {newRole ? 'ADD ROLE' : 'SAVE'}
              </Button>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportSettings;
