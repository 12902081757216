import { Button } from 'react-bootstrap';
import GridLayout from 'react-grid-layout';
import { SetStateAction, useEffect, useState } from 'react';
import { ArrowCounterclockwise, GripVertical, Trash, X } from 'react-bootstrap-icons';
import { IRLSRole } from '../../types/report.d';
import { IGroupReportsUsers } from '../../types/group.d';
import { useLazyGetClientGroupsQuery } from '../../features/client/clientApi.slice';
import { ICreateRLSRoleDto } from '../../features/report/reportApi.slice';

interface IReportRLSRolesSettingsProps {
  reportId: string;
  clientId: string | null;
  deletingRoles: string[];
  editingRoles: IRLSRole[];
  newRole: ICreateRLSRoleDto | null;
  setDeletingRoles: React.Dispatch<SetStateAction<string[]>>;
  setEditingRoles: React.Dispatch<SetStateAction<IRLSRole[]>>;
  setNewRole: React.Dispatch<SetStateAction<ICreateRLSRoleDto | null>>;
}

function ReportRLSRolesSettings(props: IReportRLSRolesSettingsProps) {
  const {
    reportId,
    clientId,
    deletingRoles,
    setDeletingRoles,
    editingRoles,
    setEditingRoles,
    newRole,
    setNewRole,
  } = props;
  const [getClientGroups] = useLazyGetClientGroupsQuery();
  const [groups, setGroups] = useState<IGroupReportsUsers[]>([]);

  const onUpdateRoleOrder = (grid: GridLayout.Layout[]) => {
    if (!editingRoles) return;
    const newOrder: IRLSRole[] = grid
      .sort((g1, g2) => (g1.y > g2.y ? 1 : -1))
      .flatMap((g) => {
        const role = editingRoles.find((r) => r.id === g.i);
        if (!role) return [];
        return { ...role, priorityOrder: g.y };
      });
    setEditingRoles(newOrder);
  };

  const onUpdateRole = (
    id: string,
    name: keyof IRLSRole,
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    let parsedValue: string | boolean | null;
    const { type, checked, value } = event.target as HTMLInputElement;
    if (name === 'groupId' && value === '') parsedValue = null;
    else if (type === 'checkbox') parsedValue = checked;
    else parsedValue = value;
    setEditingRoles(
      editingRoles.map((role) => {
        if (role.id !== id) return role;
        return {
          ...role,
          [name]: parsedValue,
        };
      })
    );
  };

  useEffect(() => {
    if (clientId)
      getClientGroups(clientId)
        .unwrap()
        .then(({ groups: g }) => setGroups(g ?? []));
  }, [clientId]);

  return (
    <div id="rls-roles-settings">
      <span className="rls-title"> RLS Settings </span>
      <div className="roles-titles">
        <span>NAME</span>
        <span>GROUP</span>
        <span>GUARDIANS</span>
      </div>
      <GridLayout
        cols={1}
        width={490}
        rowHeight={32}
        isResizable={false}
        draggableHandle=".role-ordering-icon"
        onDragStop={onUpdateRoleOrder}
        className="rls-roles-grid"
      >
        {editingRoles.map((role) => {
          const isDeleted = deletingRoles.includes(role.id);
          return (
            <div id="rls-role" className={isDeleted ? 'deleted' : ''} key={role.id}>
              <div className="grid-name-container">
                {!isDeleted && <GripVertical className="role-ordering-icon" size={24} />}
                <input
                  value={role.name}
                  onChange={(event) => onUpdateRole(role.id, 'name', event)}
                  disabled={isDeleted}
                />
              </div>
              {!role.forGuardians && (
                <select
                  className="role-group-select"
                  value={role.groupId ?? ''}
                  onChange={(event) => onUpdateRole(role.id, 'groupId', event)}
                  disabled={isDeleted}
                >
                  <option value="">-</option>
                  {groups.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              )}
              {role.forGuardians && <div className="role-group-select px-3"> Not available </div>}
              <div className="role-buttons">
                <input
                  id="rls-role-guardians"
                  type="checkbox"
                  title="For guardians"
                  checked={role.forGuardians}
                  onChange={(event) => onUpdateRole(role.id, 'forGuardians', event)}
                  disabled={isDeleted}
                />
                {isDeleted && (
                  <ArrowCounterclockwise
                    type="button"
                    onClick={() => setDeletingRoles(deletingRoles.filter((id) => id !== role.id))}
                  />
                )}
                {!isDeleted && (
                  <Trash
                    type="button"
                    onClick={() => setDeletingRoles([...deletingRoles, role.id])}
                  />
                )}
              </div>
            </div>
          );
        })}
      </GridLayout>
      {!!newRole && (
        <div className="new-rls-role">
          <input
            value={newRole.name}
            placeholder="Role name..."
            onChange={({ target }) => setNewRole({ ...newRole, name: target.value })}
          />
          <label htmlFor="new-role-input">
            For Guardians
            <input
              id="new-role-input"
              type="checkbox"
              checked={newRole.forGuardians}
              onChange={({ target }) => setNewRole({ ...newRole, forGuardians: target.checked })}
            />
          </label>
          <Button onClick={() => setNewRole(null)}>
            <X />
          </Button>
        </div>
      )}
      {!newRole && (
        <Button
          className="add-role-btn"
          onClick={() => setNewRole({ id: reportId, name: '', forGuardians: false })}
        >
          Add new role
        </Button>
      )}
    </div>
  );
}

export default ReportRLSRolesSettings;
