import { Embed, models } from 'powerbi-client';
import { useMediaQuery } from 'react-responsive';
import { JSX, RefObject, useEffect, useRef, useState } from 'react';
import Loader from '../../Loader/Loader';
import {
  setCurrentReportPageName,
  selectCurrentReportPageName,
} from '../../../features/report/report.slice';
import { useAppDispatch, useAppSelector } from '../../../features/hooks';

export interface IPBIEmbedProps {
  visualType: string;
  id: string | undefined;
  embedUrl: string | undefined;
  embedToken: string | undefined;
  retryTimeoutId: RefObject<number | null>;
  idleUserTimeoutId: RefObject<number | null>;
  keepCapacityOnInterval: RefObject<number | null>;
  startIdleUserTimeout: () => void;
  startKeepCapacityAliveInterval: () => void;
}

/**
 * When the capacity is started by the backend, te backend returns the embed info before the capacity finished to start.
 * In this case, the page is reloaded to attempt again until the capacity finished to start and loads the model.
 * */
const CAPACITY_ERROR_MESSAGES = [
  'Capacity operation failed with error code NotFound',
  'Capacity operation failed with error code CapacityNotActive',
];
const REPORT_LOADING_INITIAL_MESSAGE = 'We are preparing the report for you';
const REPORT_LONG_LOADING_MESSAGE = 'This operation might take a few seconds...';

export function PBIEmbed(props: IPBIEmbedProps): JSX.Element {
  const {
    visualType,
    id,
    embedUrl,
    embedToken,
    retryTimeoutId,
    idleUserTimeoutId,
    keepCapacityOnInterval,
    startIdleUserTimeout,
    startKeepCapacityAliveInterval,
  } = props;
  const dispatch = useAppDispatch();
  const embedContainerRef = useRef<HTMLDivElement>(null);
  const [report, setReport] = useState<Embed | null>(null);
  const pageName = useAppSelector(selectCurrentReportPageName);
  const isMobilePortrait = useMediaQuery({ orientation: 'portrait' });
  const [isEmbedLoading, setIsEmbedLoading] = useState<boolean>(true);
  const [areParamsLoading, setAreParamsLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string>(REPORT_LOADING_INITIAL_MESSAGE);

  const getVisualConfig = (): models.IReportEmbedConfiguration => ({
    type: visualType,
    id,
    embedUrl,
    pageName,
    accessToken: embedToken,
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
      },
      layoutType: isMobilePortrait ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
    },
  });

  const onReportError = (event: any) => {
    const reason: string | null = event?.detail?.technicalDetails?.errorInfo
      ? (event?.detail?.technicalDetails?.errorInfo[0]?.value as string)
      : null;
    if (reason && CAPACITY_ERROR_MESSAGES.includes(reason) && embedContainerRef.current) {
      setIsEmbedLoading(true);
      setLoadingMessage(REPORT_LONG_LOADING_MESSAGE);
      window.powerbi.reset(embedContainerRef.current); // Reset container to avoid displaying error message to user
      setReport(window.powerbi.embed(embedContainerRef.current, getVisualConfig()));
    } else {
      setIsEmbedLoading(false);
      // TODO: Log the reason of error
    }
  };

  const onReportRendered = () => {
    setIsEmbedLoading(false);
    if (idleUserTimeoutId.current) clearTimeout(idleUserTimeoutId.current);
    startIdleUserTimeout();
    if (!keepCapacityOnInterval.current) startKeepCapacityAliveInterval();
  };

  const onEmbedComponentUnmount = () => {
    if (keepCapacityOnInterval.current) clearInterval(keepCapacityOnInterval.current);
    if (retryTimeoutId.current) clearTimeout(retryTimeoutId.current);
    if (idleUserTimeoutId.current) clearTimeout(idleUserTimeoutId.current);
    setLoadingMessage(REPORT_LOADING_INITIAL_MESSAGE);
  };

  const onPageChange = (e: any) => {
    if (e.detail?.newPage?.name) dispatch(setCurrentReportPageName(e.detail.newPage.name));
  };

  /* Check if params are loading */
  useEffect(() => {
    if (!id || !embedToken || !embedUrl || !pageName || !embedContainerRef.current) {
      setAreParamsLoading(true);
    } else {
      setAreParamsLoading(false);
      setReport(window.powerbi.embed(embedContainerRef.current, getVisualConfig()));
    }
  }, [id, embedToken, embedUrl]);

  /* Add embed event handlers */
  useEffect(() => {
    if (report) {
      report.on('error', onReportError);
      report.on('rendered', onReportRendered);
      report.on('pageChanged', onPageChange);
    }
    return () => {
      if (report) {
        report.off('error');
        report.off('rendered');
        report.off('pageChanged');
      }
    };
  }, [report]);

  useEffect(() => {
    return () => {
      onEmbedComponentUnmount();
    };
  }, []);

  useEffect(() => {
    if (embedContainerRef.current && !areParamsLoading) {
      window.powerbi.reset(embedContainerRef.current);
      setReport(window.powerbi.embed(embedContainerRef.current, getVisualConfig()));
    }
  }, [isMobilePortrait]);

  return (
    <div
      id="embedPage"
      className={!isEmbedLoading && !areParamsLoading ? 'is-not-loading' : 'is-loading'}
    >
      <div id="embedLoader">
        <Loader message={loadingMessage} className="embed-loader" />
      </div>
      <div id="embedComponent">
        <div className={`${visualType}-style-class`} ref={embedContainerRef} />
      </div>
    </div>
  );
}
