import GridLayout from 'react-grid-layout';
import { Button, Dropdown } from 'react-bootstrap';
import { FunctionComponent, useEffect, useState } from 'react';
import { ChevronRight, GripVertical } from 'react-bootstrap-icons';
import { IReportWithPages, ReportIcons, ReportIconsMap } from '../../types/report.d';
import {
  IUpdateReportOrderDto,
  useUpdateReportOrderMutation,
  useUpdateReportSettingsMutation,
} from '../../features/report/reportApi.slice';
import { useAppDispatch } from '../../features/hooks';
import { setErrorMessage, setSuccessMessage } from '../../features/layout/layout.slice';
import {
  useCreateSectionMutation,
  useGetClientSectionsQuery,
  useUpdateSectionOrderMutation,
} from '../../features/section/sectionApi.slice';

interface IReportSettingsProps {
  clientId: string;
}

function ReportSettings({ clientId }: IReportSettingsProps) {
  const dispatch = useAppDispatch();
  const { data: sections } = useGetClientSectionsQuery(clientId);
  const [createSection] = useCreateSectionMutation();
  const [updateReportOrder] = useUpdateReportOrderMutation();
  const [updateSettings] = useUpdateReportSettingsMutation();
  const [updateSectionOrder] = useUpdateSectionOrderMutation();
  const [ReportIcon, setReportIcon] = useState<FunctionComponent>();
  const [newSection, setNewSection] = useState<string | null>(null);
  const [editingReport, setEditingReport] = useState<IReportWithPages | null>(null);

  const onUpdateDisplayName = () => {
    if (!editingReport?.id) return;
    updateSettings({
      reportId: editingReport.id,
      displayName: editingReport.displayName,
      icon: editingReport.icon,
      sectionId: editingReport.sectionId || null,
    })
      .unwrap()
      .then(() => dispatch(setSuccessMessage('Report successfully updated')))
      .catch((err) =>
        dispatch(setErrorMessage(`Report update failed ${err?.data?.message ?? ''}`))
      );
  };

  const onUpdateSectionOrder = (grid: GridLayout.Layout[]) => {
    if (!sections) return;
    const newOrder: IUpdateReportOrderDto[] = grid
      .sort((g1, g2) => (g1.y > g2.y ? 1 : -1))
      .map((g) => ({ id: g.i, displayOrder: g.y }));
    updateSectionOrder(newOrder)
      .unwrap()
      .catch((err) =>
        dispatch(setErrorMessage(`Sections order update failed ${err?.data?.message ?? ''}`))
      );
  };

  const onUpdateReportOrder = (grid: GridLayout.Layout[]) => {
    if (!sections) return;
    const newOrder: IUpdateReportOrderDto[] = grid
      .sort((g1, g2) => (g1.y > g2.y ? 1 : -1))
      .map((g) => ({ id: g.i, displayOrder: g.y }));
    updateReportOrder(newOrder)
      .unwrap()
      .catch((err) =>
        dispatch(setErrorMessage(`Reports order update failed ${err?.data?.message ?? ''}`))
      );
  };

  const onAddSection = () => {
    if (!newSection) return;
    createSection({ clientId, name: newSection })
      .unwrap()
      .then(() => dispatch(setSuccessMessage('Section successfully created')))
      .catch((err) =>
        dispatch(setErrorMessage(`Section creation failed ${err?.data?.message ?? ''}`))
      )
      .finally(() => setNewSection(null));
  };

  useEffect(() => {
    if (sections?.length) {
      let selectedReport: IReportWithPages | null;
      const defaultReport = sections.find((s) => s.reports.length)?.reports[0] ?? null;
      if (editingReport) {
        const selectedSection = sections.find((section) => section.id === editingReport.sectionId);
        selectedReport =
          selectedSection?.reports.find((report) => report.id === editingReport.id) ??
          defaultReport;
      } else {
        selectedReport = defaultReport;
      }
      setEditingReport(selectedReport);
    }
  }, [sections]);

  useEffect(() => {
    if (editingReport) {
      setReportIcon(ReportIconsMap[editingReport.icon]);
      setNewSection(null);
    }
  }, [editingReport]);

  useEffect(() => {
    if (editingReport) setReportIcon(ReportIconsMap[editingReport.icon]);
  }, [editingReport]);

  return (
    <div id="adminReportSettings">
      <span className="report-title">Reports</span>
      <div className="report-content">
        {sections && (
          <div className="left-container">
            <div className="new-section-container">
              {newSection === null && (
                <Button className="add-section-btn" onClick={() => setNewSection('')}>
                  ADD NEW SECTION
                </Button>
              )}
              {newSection !== null && (
                <>
                  <input
                    placeholder="Add new section..."
                    value={newSection}
                    onChange={({ target }) => setNewSection(target.value)}
                  />
                  <Button onClick={() => setNewSection(null)}>Cancel</Button>
                  <Button onClick={onAddSection}>Save</Button>
                </>
              )}
            </div>
            <GridLayout
              cols={1}
              width={900}
              rowHeight={42}
              isResizable={false}
              draggableHandle=".section-ordering-icon"
              onDragStop={onUpdateSectionOrder}
            >
              {sections.map((section, i) => (
                <div
                  className="section-grid-container"
                  key={section.id}
                  data-grid={{ x: 0, y: i, w: 900, h: section.reports.length + 1 }}
                >
                  <div className="grid-name-container">
                    <GripVertical className="section-ordering-icon" size={24} />
                    <span>{section.name}</span>
                  </div>
                  <GridLayout
                    cols={1}
                    width={800}
                    rowHeight={38}
                    isResizable={false}
                    draggableHandle=".report-ordering-icon"
                    onDragStop={onUpdateReportOrder}
                  >
                    {section.reports.map((report) => (
                      <Button
                        key={report.id}
                        className="report-select-btn"
                        onClick={() => setEditingReport(report)}
                        active={editingReport?.id === report.id}
                      >
                        <div className="grid-name-container">
                          <GripVertical className="report-ordering-icon" size={24} />
                          <span className="report-select-btn-name">{report.displayName}</span>
                        </div>
                        <ChevronRight />
                      </Button>
                    ))}
                  </GridLayout>
                </div>
              ))}
            </GridLayout>
          </div>
        )}
        <div className="right-container">
          {editingReport && (
            <form className="report-form">
              <div className="form-group">
                <span className="report-form-label">Original name</span>
                <span className="report-form-value">{editingReport.name}</span>
              </div>
              <div className="form-group">
                <span className="report-form-label">Displayed name</span>
                <input
                  name="displayName"
                  className="report-name-input"
                  value={editingReport.displayName}
                  onChange={({ target }) =>
                    setEditingReport({ ...editingReport, displayName: target.value })
                  }
                  maxLength={20}
                />
              </div>
              <div className="form-group">
                <span className="report-form-label">Section</span>
                <select
                  value={editingReport.sectionId ?? ''}
                  onChange={({ target }) =>
                    setEditingReport({ ...editingReport, sectionId: target.value })
                  }
                  className="report-name-input"
                >
                  {sections?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group" id="report-icons-container">
                <span className="report-form-label">Icon</span>
                <Dropdown>
                  <Dropdown.Toggle className="report-icon-toggle" variant="secondary">
                    {ReportIcon && <ReportIcon />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="report-icon-select">
                    {Object.entries(ReportIcons).map(([key, value]) => {
                      const Icon = ReportIconsMap[value];
                      return (
                        <Dropdown.Item
                          className="report-icon"
                          key={key}
                          onClick={() => {
                            setEditingReport({ ...editingReport, icon: value });
                            setReportIcon(Icon);
                          }}
                        >
                          <Icon />
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Button className="edit-report-btn" type="button" onClick={onUpdateDisplayName}>
                SAVE
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportSettings;
