import { JSX } from 'react';
import { Button } from 'react-bootstrap';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { SessionType } from '../../types/user.d';
import { msalConfig } from '../../config/authConfig';
import { getClientLogo } from '../../types/client.d';
import { useAppDispatch } from '../../features/hooks';
import { azureSigninRedirect } from '../../utils/session.util';
import { setSessionType } from '../../features/auth/auth.slice';
import { buildLoaderAction, setIsPageLoading } from '../../features/layout/layout.slice';
import { useLazyGetClientConfigurationQuery } from '../../features/client/clientApi.slice';

const clientCode: string = window.location.hostname.split('.')[0];

function Signin(): JSX.Element {
  const dispatch = useAppDispatch();
  const [getClientConfig] = useLazyGetClientConfigurationQuery();

  const handleSignInRedirect = (sessionType: SessionType) => {
    getClientConfig()
      .unwrap()
      .then(async (config) => {
        const instance = new PublicClientApplication(msalConfig(sessionType, config));
        localStorage.setItem('msalConfig', JSON.stringify(instance.getConfiguration().auth));
        localStorage.setItem('SessionType', sessionType);
        await instance.initialize();
        dispatch(setSessionType(sessionType));
        dispatch(setIsPageLoading(buildLoaderAction(true)));
        azureSigninRedirect(instance, sessionType);
      });
  };

  return (
    <UnauthenticatedTemplate>
      <div id="signinPage">
        <div className="signin-container">
          <img
            className="signin-image"
            src={getClientLogo(clientCode)}
            alt={`${clientCode} logo`}
          />
          <Button
            className="signin-button"
            variant="primary"
            onClick={() => handleSignInRedirect(SessionType.STAFF)}
          >
            Staff Login
          </Button>
          <Button
            className="signin-button"
            variant="primary"
            onClick={() => handleSignInRedirect(SessionType.GUARDIAN)}
          >
            Guardian Login
          </Button>
        </div>
      </div>
    </UnauthenticatedTemplate>
  );
}

export default Signin;
