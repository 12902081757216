import { useAppSelector } from '../../features/hooks';
import { ReportIconsMap } from '../../types/report.d';
import { ISectionReports } from '../../types/section.d';
import ReportTile from '../../components/ReportTile/ReportTile';
import illustration from '../../assets/analytics-illustration.png';
import { useGetUserQuery } from '../../features/user/userApi.slice';
import { selectCurrentClient } from '../../features/client/client.slice';

function Home() {
  const { data: user } = useGetUserQuery();
  const client = useAppSelector(selectCurrentClient);

  return (
    <div id="homepage">
      <div role="none" className="welcome-banner">
        <span className="client-title"> {client?.title} </span>
        <span className="client-description">{client?.description}</span>
        <img src={illustration} alt="Analytics illustration" className="analytics-illustration" />
      </div>
      {user?.sections.map((section: ISectionReports) => (
        <div className="home-section" key={section.id}>
          <span className="section-name">{section.name}</span>
          <div className="report-tiles">
            {section.reports.map((report) => {
              const icon = ReportIconsMap[report.icon];
              return (
                <ReportTile
                  name={report.displayName}
                  linkTo={`/powerbi/${report.powerbiId}/api/reports/${report.pbiReportId}/${
                    report.pages.find((page) => page.order === 0)?.name
                  }`}
                  BsIcon={icon}
                  key={report.id}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Home;
